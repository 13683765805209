import { MutationTree } from "vuex"
import type { State } from "./state"
import {
    assessmentConfig,
    CustomQuestionCartItem,
    GradingItem,
    ModifyingCodingQuestion,
    ModifyingCustomQuestion,
    ModifyingQualifyQuestion,
    QualifyingQuestionCartItem,
    TestCartItem,
    TypeAssessmentDetails,
    TypeCustomQuestionCart,
    TypeGradingList,
    TypeQualifyingQuestionCart,
    TypeReportSetting,
    TypeSelectTestCart,
} from "./types"

import { rootState } from "../.."

export enum MutationType {
    SET_CREATE_ASSESSMENT = "SET_CREATE_ASSESSMENT",
    ADD_TEST_TO_CART = "ADD_TEST_TO_CART",
    UPDATE_TEST_LIBRARY_FROM_CART = "UPDATE_TEST_LIBRARY_FROM_CART",
    REMOVE_TEST_FROM_CART = "REMOVE_TEST_FROM_CART",
    CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS",
    BULK_ADD_TEST_TO_CART = "BULK_ADD_TEST_TO_CART",
    BULK_ADD_QUESTION_TO_CART = "BULK_ADD_QUESTION_TO_CART",
    ADD_CUSTOM_QUESTION_TO_CART = "ADD_CUSTOM_QUESTION_TO_CART",
    REMOVE_CUSTOM_QUESTION_FROM_CART = "REMOVE_CUSTOM_QUESTION_FROM_CART",
    SET_WELCOME_VIDEO_PATH = "SET_WELCOME_VIDEO_PATH",
    SET_EXTERNAL_WELCOME_VIDEO_PATH = "SET_EXTERNAL_WELCOME_VIDEO_PATH",
    ADD_QUALIFYING_QUESTION_TO_CART = "ADD_QUALIFYING_QUESTION_TO_CART",
    REMOVE_QUALIFYING_QUESTION_FROM_CART = "REMOVE_QUALIFYING_QUESTION_FROM_CART",
    BULK_ADD_QUALIFYING_QUESTION_TO_CART = "BULK_ADD_QUALIFYING_QUESTION_TO_CART",
    UPDATE_CUSTOM_QUESTION_TO_CART = "UPDATE_CUSTOM_QUESTION_TO_CART",
    UPDATE_CODING_QUESTION_TO_CART = "UPDATE_CODING_QUESTION_TO_CART",
    UPDATE_QUALIFYING_QUESTION_TO_CART = "UPDATE_QUALIFYING_QUESTION_TO_CART",
    SET_ACTIVE_ACCORDION = "SET_ACTIVE_ACCORDION",
    UPDATE_QUESTION_FROM_CART = "UPDATE_QUESTION_FROM_CART",
    UPDATE_TEST_FROM_CART = "UPDATE_TEST_FROM_CART",
    SET_QUALIFYING_QUESTION_TO_CART = "SET_QUALIFYING_QUESTION_TO_CART",
    SET_ASSESSMENT_CONFIG = "SET_ASSESSMENT_CONFIG",
    SET_REPORT_SETTINGS = "SET_REPORT_SETTINGS",
    RESET_ASSESSMENT_CONFIG = "RESET_ASSESSMENT_CONFIG",
    SET_ASSESSMENT_STATUS = "SET_ASSESSMENT_STATUS",
    SET_GRADING_LIST = "SET_GRADING_LIST",
    APPEND_GRADING_LIST = "APPEND_GRADING_LIST",
    REMOVE_GRADING_LIST_ITEM = "REMOVE_GRADING_LIST_ITEM",
    UPDATE_GRADING_LIST_ITEM = "UPDATE_GRADING_LIST_ITEM",
    UPDATE_QUESTION_LIMIT = "UPDATE_QUESTION_LIMIT",
    UPDATE_OPTION_LIMIT = "UPDATE_OPTION_LIMIT",
    SET_CUSTOMIZED_TEMPLATE = "SET_CUSTOMIZED_TEMPLATE",
}

export type Mutations = {
    [MutationType.SET_CREATE_ASSESSMENT](
        state: State,
        assessmentObject: TypeAssessmentDetails
    ): void
    [MutationType.ADD_TEST_TO_CART](state: State, cartItems: TestCartItem): void

    [MutationType.BULK_ADD_TEST_TO_CART](
        state: State,
        cartItems: TypeSelectTestCart
    ): void
    [MutationType.BULK_ADD_QUESTION_TO_CART](
        state: State,
        cartItems: TypeCustomQuestionCart
    ): void
    [MutationType.REMOVE_TEST_FROM_CART](
        state: State,
        cartItemIndex: number
    ): void
    [MutationType.REMOVE_CUSTOM_QUESTION_FROM_CART](
        state: State,
        cartItemIndex: number
    ): void
    [MutationType.UPDATE_QUESTION_FROM_CART](
        state: State,
        newCartValue: any
    ): void
    [MutationType.UPDATE_TEST_FROM_CART](state: State, newCartValue: any): void
    [MutationType.SET_QUALIFYING_QUESTION_TO_CART](
        state: State,
        newCartValue: any
    ): void
    [MutationType.CLEAR_CART_ITEMS](state: State): void
    [MutationType.ADD_CUSTOM_QUESTION_TO_CART](
        state: State,
        cartItems: CustomQuestionCartItem
    ): void
    [MutationType.SET_WELCOME_VIDEO_PATH](state: State, videoLink: String): void
    [MutationType.SET_EXTERNAL_WELCOME_VIDEO_PATH](
        state: State,
        videoLink: String
    ): void
    [MutationType.SET_ACTIVE_ACCORDION](
        state: State,
        activeAccordion: String
    ): void
    [MutationType.ADD_QUALIFYING_QUESTION_TO_CART](
        state: State,
        cartItem: QualifyingQuestionCartItem
    ): void
    [MutationType.BULK_ADD_QUALIFYING_QUESTION_TO_CART](
        state: State,
        cartItems: TypeQualifyingQuestionCart
    ): void
    [MutationType.REMOVE_QUALIFYING_QUESTION_FROM_CART](
        state: State,
        cartItemIndex: number
    ): void
    [MutationType.UPDATE_CUSTOM_QUESTION_TO_CART](
        state: State,
        cartDetails: ModifyingCustomQuestion
    ): void
    [MutationType.UPDATE_CODING_QUESTION_TO_CART](
        state: State,
        cartDetails: ModifyingCodingQuestion
    ): void
    [MutationType.UPDATE_QUALIFYING_QUESTION_TO_CART](
        state: State,
        cartDetails: ModifyingQualifyQuestion
    ): void
    [MutationType.SET_ASSESSMENT_CONFIG](
        state: State,
        config: assessmentConfig
    ): void
    [MutationType.SET_REPORT_SETTINGS](
        state: State,
        reportObject: TypeReportSetting
    ): void
    [MutationType.RESET_ASSESSMENT_CONFIG](
        state: State,
        config: assessmentConfig
    ): void
    [MutationType.SET_ASSESSMENT_STATUS](state: State, status: String): void
    [MutationType.UPDATE_TEST_LIBRARY_FROM_CART](
        state: State,
        newCartValue: any
    ): void
    [MutationType.SET_GRADING_LIST](state: State, items: TypeGradingList): void
    [MutationType.APPEND_GRADING_LIST](
        state: State,
        items: TypeGradingList
    ): void
    [MutationType.REMOVE_GRADING_LIST_ITEM](
        state: State,
        itemIndex: number
    ): void
    [MutationType.UPDATE_GRADING_LIST_ITEM](
        state: State,
        item: { gradingItem: GradingItem; index: number }
    ): void
    [MutationType.UPDATE_QUESTION_LIMIT](state: State, limit: Number): void
    [MutationType.UPDATE_OPTION_LIMIT](state: State, limit: Number): void
    [MutationType.SET_CUSTOMIZED_TEMPLATE](
        state: State,
        template: { shortlisted: String }
    ): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.UPDATE_TEST_LIBRARY_FROM_CART](state, newCartValue) {
        const index = state.selectTestCart.findIndex(
            (x) => x.id === newCartValue.id
        )
        state.selectTestCart[index].questionLimit = newCartValue.questionLimit
        state.selectTestCart[index].timeLimit = newCartValue.timeLimit
        state.selectTestCart[index].questionCountType =
            newCartValue.questionCountType
        state.selectTestCart[index].level = newCartValue.level
    },
    [MutationType.SET_CREATE_ASSESSMENT](state, assessmentObject) {
        state.assessmentDetails = assessmentObject
    },
    [MutationType.ADD_TEST_TO_CART](state, cartItems) {
        state.selectTestCart.push(cartItems)
    },
    [MutationType.BULK_ADD_TEST_TO_CART](state, cartItems) {
        state.selectTestCart = cartItems
    },
    [MutationType.REMOVE_TEST_FROM_CART](state, cartItemIndex) {
        state.selectTestCart.splice(cartItemIndex, 1)
    },
    [MutationType.REMOVE_CUSTOM_QUESTION_FROM_CART](state, cartItemIndex) {
        state.customQuestionCart.splice(cartItemIndex, 1)
    },
    [MutationType.UPDATE_QUESTION_FROM_CART](state, newCartValue) {
        state.customQuestionCart = newCartValue
    },
    [MutationType.UPDATE_TEST_FROM_CART](state, newCartValue) {
        state.selectTestCart = newCartValue
    },
    [MutationType.SET_QUALIFYING_QUESTION_TO_CART](state, newCartValue) {
        state.qualifyingQuestionCart = newCartValue
    },
    [MutationType.CLEAR_CART_ITEMS](state) {
        state.selectTestCart = []
        state.customQuestionCart = []
        state.qualifyingQuestionCart = []
    },
    [MutationType.ADD_CUSTOM_QUESTION_TO_CART](state, cartItems) {
        state.customQuestionCart.push(cartItems)
    },
    [MutationType.BULK_ADD_QUESTION_TO_CART](state, cartItems) {
        state.customQuestionCart = cartItems
    },
    [MutationType.SET_WELCOME_VIDEO_PATH](state, videoLink: String) {
        state.welcomeVideoPath = videoLink
    },
    [MutationType.SET_EXTERNAL_WELCOME_VIDEO_PATH](state, videoLink: String) {
        state.externalWelcomeVideoLink = videoLink
    },
    [MutationType.SET_ACTIVE_ACCORDION](state, activeAccordion: String) {
        let currentAccordion = document.getElementById(
            activeAccordion.toString()
        ).childNodes[0].childNodes[0] as HTMLElement | null
        currentAccordion.scrollIntoView(true)
        window.scrollBy(0, -30)
        state.activeAccordion = activeAccordion
    },
    [MutationType.ADD_QUALIFYING_QUESTION_TO_CART](state, cartItem) {
        state.qualifyingQuestionCart.push(cartItem)
    },
    [MutationType.REMOVE_QUALIFYING_QUESTION_FROM_CART](state, cartItemIndex) {
        state.qualifyingQuestionCart.splice(cartItemIndex, 1)
    },
    [MutationType.BULK_ADD_QUALIFYING_QUESTION_TO_CART](state, cartItems) {
        if (cartItems) {
            state.qualifyingQuestionCart = cartItems
        }
    },
    [MutationType.SET_REPORT_SETTINGS](state, reportObject) {
        state.reportSettings = {
            ...state.reportSettings,
            ...reportObject,
        }
    },
    [MutationType.SET_ASSESSMENT_CONFIG](state, config) {
        state.assessmentConfig = {
            ...state.assessmentConfig,
            ...config,
        }
        if (rootState.Global.isReseller) {
            state.assessmentConfig.skipRegistration = true
            state.assessmentConfig.sendAssessmentResultEmail = false
            state.assessmentConfig.invitationEmailLinkValidity = 0
            state.assessmentConfig.AssessmentDeadlineType = "NO_OF_DAYS"
            state.assessmentConfig.invitationEmailLinkExpiryDate = ""
        } else {
            state.assessmentConfig.recipientList =
                config.recipientList || state.assessmentConfig.recipientList
        }
    },
    [MutationType.RESET_ASSESSMENT_CONFIG](state, config) {
        state.assessmentConfig = {
            ...state.assessmentConfig,
            ...config,
        }
        if (rootState.Global.isReseller) {
            state.assessmentConfig.skipRegistration = true
            state.assessmentConfig.sendAssessmentResultEmail = false
            state.assessmentConfig.invitationEmailLinkValidity = 0
            state.assessmentConfig.AssessmentDeadlineType = "NO_OF_DAYS"
            state.assessmentConfig.invitationEmailLinkExpiryDate = ""
        } else {
            state.assessmentConfig.recipientList =
                config.recipientList || state.assessmentConfig.recipientList
        }
    },
    [MutationType.UPDATE_CODING_QUESTION_TO_CART](state, cartDetails) {
        const index = cartDetails.index
        delete cartDetails["index"]
        state.selectTestCart.splice(index, 1, cartDetails)
    },
    [MutationType.UPDATE_CUSTOM_QUESTION_TO_CART](state, cartDetails) {
        const index = cartDetails.index
        delete cartDetails["index"]
        state.customQuestionCart.splice(index, 0, cartDetails)
    },
    [MutationType.UPDATE_QUALIFYING_QUESTION_TO_CART](state, cartDetails) {
        const index = cartDetails.index
        delete cartDetails["index"]
        state.qualifyingQuestionCart.splice(index, 0, cartDetails)
    },
    [MutationType.SET_ASSESSMENT_STATUS](state, status) {
        state.assessmentStatus = status
    },
    [MutationType.SET_GRADING_LIST](state, items) {
        state.gradingItems = items
    },
    [MutationType.APPEND_GRADING_LIST](state, items) {
        const newItems: TypeGradingList = [
            ...state.gradingItems,
            ...items,
        ] as TypeGradingList
        newItems.sort((a, b) => a.min - b.min)
        state.gradingItems = newItems
    },
    [MutationType.REMOVE_GRADING_LIST_ITEM](state, itemIndex) {
        state.gradingItems.splice(itemIndex, 1)
    },
    [MutationType.UPDATE_GRADING_LIST_ITEM](state, item) {
        state.gradingItems[item.index] = item.gradingItem
    },
    [MutationType.UPDATE_QUESTION_LIMIT](state, limit) {
        state.questionLimit = limit
    },
    [MutationType.UPDATE_OPTION_LIMIT](state, limit) {
        state.optionLimit = limit
    },
    [MutationType.SET_CUSTOMIZED_TEMPLATE](state, template) {
        state.customizedEmailTemplates.shortlisted = template.shortlisted
    },
}
